import "antd/dist/antd.css";
import React from "react";
import logo from "./logo-no-background.png";
import "./App.css";
// contracts

function App() {
  return (
    <div className="App">
      <div className="App-splash">
        <header className="App-splash-header">
          <img src={logo} className="App-splash-logo" alt="logo" />
          <p>InfuseSoul</p>
        </header>
      </div>
    </div>
  );
}

export default App;
